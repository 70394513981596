<template>
  <section>
    <TopBanner
      title="Licensing"
      :src="require('@/assets/images/banners/banner_aboutus.jpg')"
    />
    <v-container>
      <div id="licensing">
        <div class="text-center">
          <p class="primary--text text-h5 text-sm-h3 font-weight-bold">
            Licensed States
          </p>
          <p>
            {{ $store.state.company.name }}. (<a
              :href="`${$store.state.nmlsconsumeraccess}/${$store.state.company.nmls}`"
              target="_blank"
              >{{ $store.state.company.nmls }}</a
            >) is a licensed mortgage lender. the following states require
            disclosure of licensing information.
          </p>
        </div>

        <div>
          <v-data-table
            :headers="headers"
            :items="licenseItems"
            item-key="licenseNumber"
            :items-per-page="200"
            class="elevation-1"
            fixed-header
            hide-default-footer
          >
            <template v-slot:item="{ item, index }">
              <tr :class="index % 2 == 0 ? `lightgrey` : ``">
                <td>{{ item.codeName }}</td>
                <td>{{ item.reference1 }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <br />
        <v-sheet color="lightgrey" class="text-center py-6">
          <div>
            Licenses can be verified at NMLS Website:
            <a
              :href="`${$store.state.nmlsconsumeraccess}/${$store.state.company.nmls}`"
              target="_blank"
              >www.nmlsconsumeraccess.org</a
            >
          </div>
          <div>
            * - Licensed by the Department of Financial Protection and
            Innovation under the California Residential Mortgage Lending Act":
            <a href="https://www.newwavelending.com/licensing" target="_blank"
              >https://www.newwavelending.com/licensing</a
            >
          </div>
        </v-sheet>
      </div>
    </v-container>
    <Footer />
  </section>
</template>

<script>
import TopBanner from "@/components/TopBanner";
import Footer from "@/views/layouts/Footer";
import axios from "axios";

export default {
  components: {
    TopBanner,
    Footer,
  },
  async created() {
    this.licenseItems = await axios
      .get(
        `${process.env.VUE_APP_API_BASE_URL}/api/Feature/public/LicensedStates`,
        {
          headers: {
            Authorization: `Basic ${btoa(
              `${this.$store.state.basicAuth.userName}:${this.$store.state.basicAuth.password}`
            )}`,
          },
        }
      )
      .then((resp) => {
        return resp.data;
      })
      .catch(() => []);
  },
  data() {
    return {
      headers: [
        {
          text: "State",
          value: "state",
        },
        {
          text: "License Number",
          value: "licenseNumber",
        },
      ],
      licenseItems: [],
    };
  },
};
</script>
